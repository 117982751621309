import React from 'react';

const flagPart = {
  height: '7px',
  width: '70px',
  padding: '0 7px',
};

const flagPartBlack = {
  ...flagPart,
  backgroundColor: '#000',
};

const flagPartRed = {
  ...flagPart,
  backgroundColor: '#f00',
};

const flagPartGold = {
  ...flagPart,
  backgroundColor: '#ffc107',
};

const MadeInGermany = () => (
  <div style={{ maxWidth: '224px', margin: '0 auto' }}>
    <div
      style={{
        display: `flex`,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div style={flagPartBlack} />
      <div style={flagPartRed} />
      <div style={flagPartGold} />
    </div>
    <p style={{ color: '#444', fontSize: '0.8rem', margin: '4px 0', textAlign: 'center' }}>
      URLAUB IN DEUTSCHLAND
    </p>
  </div>
);

export default MadeInGermany
