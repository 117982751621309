import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.css';
import './layout.css';
import './custom.css';
import MadeInGermany from './made-in-germany';
import LogoAutobahnNetwork from '../components/images/logo-autobahn-network';

const Layout = ({ children, fluidContainer = false, backToHome = false }) => {
  let containerStyle = {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `0 1.0875rem 1.45rem`,
  };

  if (fluidContainer) {
    containerStyle = {
      margin: `0 auto`,
      padding: `0`,
    };
  }

  const footerStyle = {
    backgroundColor: '#FFF',
    borderTop: '1px solid #FFF',
    paddingBottom: '90px',
    paddingTop: '75px',
    marginTop: '40px',
  };

  const footerContentStyle = {
    margin: '0 auto',
    maxWidth: '960px',
  };

  const linkStyle = {
    color: '#000',
    marginBottom: '4px',
  };

  const footerContent = (
    <Container fluid="lg" style={footerContentStyle}>
      <Row style={{ marginTop: '24px' }}>
        <Col xs={12} sm={4} lg={4} style={{ marginTop: '6px' }}>
          <MadeInGermany />
          <br />
        </Col>
        <Col xs={12} sm={4} lg={5}>
          <p style={{ color: '#62b7f0', fontSize: '0.8rem', textAlign: 'center' }}>
            #AuszeitWremen #Nordsee<br />#Wattenmeer #UrlaubInDeutschland
          </p>
        </Col>
        <Col xs={12} sm={4} lg={3}>
          <Link to="/imprint"><p style={linkStyle}>Impressum</p></Link>
        </Col>
      </Row>
    </Container>
  );


  const claimContent = (
    <Container css={footerContentStyle}>
      <Row>
        <Col xs={12} sm={10} lg={{ offset: 1, size: 10 }}>
          <span className="claim">Auszeit in Wremen</span>
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <div className="layoutContainer" style={containerStyle}>
        {backToHome && (
          <Link to="/" style={{ position: 'absolute', left: '24px', top: '50px' }}>
            <FontAwesomeIcon color="#62b7f0" icon={faArrowAltCircleLeft} size="2x" />
          </Link>
        )}
        <main>{children}</main>
        {claimContent}
        <footer style={footerStyle}>
          {footerContent}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
